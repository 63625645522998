
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/navigation/steps/BasicUsage.vue";
import EUIStepBarThatContainsStatus from "@/views/resources/documentation/element-ui/navigation/steps/StepBarThatContainsStatus.vue";
import EUICenter from "@/views/resources/documentation/element-ui/navigation/steps/Center.vue";
import EUIStepBarWithDescription from "@/views/resources/documentation/element-ui/navigation/steps/StepBarWithDescription.vue";
import EUIStepBarWithIcon from "@/views/resources/documentation/element-ui/navigation/steps/StepBarWithIcon.vue";
import EUIVerticalStepBar from "@/views/resources/documentation/element-ui/navigation/steps/VerticalStepBar.vue";
import EUISimpleStepBar from "@/views/resources/documentation/element-ui/navigation/steps/SimpleStepBar.vue";

export default defineComponent({
  name: "steps",
  components: {
    EUIBasicUsage,
    EUIStepBarThatContainsStatus,
    EUICenter,
    EUIStepBarWithDescription,
    EUIStepBarWithIcon,
    EUIVerticalStepBar,
    EUISimpleStepBar
  },
  setup() {
    setCurrentPageTitle("Steps");
  }
});
